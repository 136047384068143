import { Container } from "./styles";
import emailIcon from "../../assets/email-icon.svg";
import phoneIcon from "../../assets/whatsapp.svg"
import { Form } from "../Form/Form";


export function Contact(){

  return(
    <Container id="contact">
      <header>
        <h2>Contact</h2>
        <p>Ready to get started with us? </p>
        <p>Contact us now !</p>
      </header>
      <div className="contacts">
        <div>
        <a href="mailto:mailto:connect@wikimedianet.com"><img src={emailIcon} alt="Email" /></a> 
          <a href="mailto:connect@wikimedianet.com">connect@wikimedianet.com</a>
        </div>
        <div>
        <a href="https://wa.me/919874821968"><img src={phoneIcon} alt="Whatsapp No" /></a>
          <a href="https://wa.me/919874821968">(+91) 9874821968</a>
        </div>  
      </div>
      <Form></Form>
    </Container>
  )
}